import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ProductDetail from '../pages/ProductDetail/index';

const Product = ({ location, pageContext }) => {
  const [translate] = useTranslation();
  const { product, brandsContext } = pageContext;

  const brand = brandsContext.find((item) => item.node.Name === product.product.brand.name);

  const title = `${product.product.brand.name} - ${product.product.name} | ${translate('seo.product.tiklagelsin')}`;
  const description = `${translate('seo.product.description1')} ${product.product.name} ${translate(
    'seo.product.description2',
  )}`;

  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: location.href,
    name: title,
    description,
  };

  return (
    <Layout
      title={translate('seo.product.tiklagelsin')}
      sidebar={false}
      brandName={product.product.brand.name}
      brandUrl={brand?.node?.NormalizedName}
      productName={product.product.name}
      location={location}
    >
      <SEO
        title={title}
        description={description}
        siteUrl={location?.pathname}
        schemaMarkup={schema}
        deepLinkUrl={`productdetail/${product?.product?.brand?.val}/${product?.product?.value}`}
      />

      <ProductDetail product={product} />
    </Layout>
  );
};

Product.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

export default Product;
